import React from 'react';
import qs from 'qs';
import { HLocation } from '@reach/router';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { UserCredentialsStep, UserInfoStep } from 'common/steps';
import { ArrowContainer, PageWrapper } from 'modules/login';

export const RegisterForm: React.FC<LoginFormProps> = ({ location }) => {
  const { navigate } = useI18next();
  const redirectUrl = location?.state?.redirectUrl || '/';
  const referrer = qs.parse(location?.search.replace('?', ''))?.referral as string;
  const [currentStep, setCurrentStep] = React.useState(1);
  const [userInfoData, setUserInfoData] = React.useState({
    firstName: '',
    lastName: '',
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const previousPage = () => {
    if (currentStep > 1) {
      previousStep();
    } else {
      navigate(redirectUrl);
    }
  };

  return (
    <>
      <ArrowContainer onClick={previousPage} />
      <PageWrapper>
        {currentStep === 1 && (
          <UserInfoStep
            {...{ currentStep, nextStep, userInfoData, setUserInfoData, redirectUrl }}
          />
        )}
        {currentStep === 2 && (
          <UserCredentialsStep
            {...{ currentStep, userInfoData, setUserInfoData, redirectUrl, referrer }}
          />
        )}
      </PageWrapper>
    </>
  );
};

type LoginFormProps = {
  location: HLocation & {
    state: {
      redirectUrl?: string;
    };
  };
};
