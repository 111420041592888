import * as i from 'types';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next, Link as GatsbyLink } from 'gatsby-plugin-react-i18next';

import { Input, PasswordInput } from 'common/form';
import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';
import { PageTransition } from 'common/layout/PageTransition';
import { StepButtonGroup } from 'modules/StepButtonGroup';
import { validation, isBrowser, triggerGtagEvent } from 'services';
import { useAuthenticationActions } from 'services/hooks';

import {
  HeaderContainer,
  ButtonContainer,
  StyledForm,
  FormContainer,
  CheckboxContainer,
  StyledInputCheckbox,
} from './styled';

export const UserCredentialsStep: React.FC<UserCredentialsStepProps> = ({
  redirectUrl, currentStep, setUserInfoData, userInfoData, referrer,
}) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const { register: registerUser, login } = useAuthenticationActions();

  React.useEffect(() => {
    //if device is mobile, scroll to top of page
    if (isBrowser() && window.innerWidth < 768) {
      window.scrollTo(0, 0);
    }
  }, []);

  const defaultValues = {
    username: '',
    password: '',
    agreeTerms: false,
  };

  const {
    register, handleSubmit, control, clearErrors, setError, formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const name = userInfoData?.firstName;

  const handleRegister = (data: i.UserRegisterFormData) => {
    const newData = {
      ...userInfoData,
      ...data,
      referrer,
      preferredSiteId: process.env.GATSBY_PREFERED_SITE_ID,
    };

    triggerGtagEvent('sign_up', { method: 'website' });
    setUserInfoData(newData);
    clearErrors();

    registerUser(newData)
      .then(() => {
        const { username, password } = data;

        login(username, password)
          .then(() => {
            navigate(redirectUrl);
          });
      })
      .catch(() => {
        setError('username', {
          message: 'email address is already in use. Please use another mail address',
        });
      });
  };

  return (
    <PageTransition>
      <StyledForm onSubmit={handleSubmit(handleRegister)}>
        <HeaderContainer>
          <Heading as="h2">{t('nice to meet you {{ name }}! You are almost ready to rumble', { name })}</Heading>
        </HeaderContainer>
        <FormContainer>
          <Input
            label={t('emailaddress')}
            {...register('username',
              { ...validation.email, ...validation.required })}
            error={errors.username}
          />
          <PasswordInput
            label={t('password')}
            error={errors.password}
            {...register('password',
              { ...validation.password, ...validation.required })}
          />
        </FormContainer>
        <ButtonContainer>
          <CheckboxContainer>
            <Controller
              name="agreeTerms"
              control={control}
              rules={{ ...validation.required }}
              render={({ field: { onChange, value } }) => (
                <StyledInputCheckbox
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Text variant="white">
              {t('agree to our')} &nbsp;
              <GatsbyLink to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">{t('terms and conditions')}</GatsbyLink>, &nbsp;
              <GatsbyLink to="/disclaimer" target="_blank" rel="noopener noreferrer">{t('disclaimer')}</GatsbyLink> and&nbsp;
              <GatsbyLink to="/privacy-policy" target="_blank" rel="noopener noreferrer">{t('privacy policy')}</GatsbyLink> &nbsp;
            </Text>
          </CheckboxContainer>

          <StepButtonGroup {...{ currentStep }} />
          <Button
            type="submit"
            size="fullWidth"
            disabled={!isValid}
            $isValid={isValid}
          >
            Submit
          </Button>
        </ButtonContainer>
      </StyledForm>
    </PageTransition>
  );
};

type UserCredentialsStepProps = {
  currentStep: 1 | 2;
  setUserInfoData: (newData: i.UserRegisterFormData) => void;
  userInfoData: i.UserBase;
  redirectUrl: string;
  referrer?: string;
};
