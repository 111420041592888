import styled from 'styled-components';
import { media } from 'styles/utils';

import { InputCheckbox } from 'common/form';

export const HeaderContainer = styled.div`
  margin-bottom: 35px;

  ${media.desktop`
    display: flex;
    align-self: end;
  `}
`;

export const StyledForm = styled.form`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1fr 0.25fr;
  height: 80vh;

  ${media.desktop`
    grid-template-rows: 0.75fr 1fr 0.50fr;
  `}
`;

export const ButtonContainer = styled.div`
  padding-bottom: 30px;
  align-self: end;
`;

export const FormContainer = styled.div`
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.desktop`
    margin-bottom: 30px;
  `}
`;

export const StyledInputCheckbox = styled(InputCheckbox)`
  align-self: flex-end;
`;
